<template>
  <v-responsive>
    <SubMenuStoreMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuStoreMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuStore></SubMenuStore>


        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>







          <v-card
            style="margin: 30px auto; border: 1px solid #eee"
            flat
          >
            <v-list two-line>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 총합 (합계)</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      당첨번호 6개를 더한 값입니다. (ex: 31회의 당첨번호 18+7+23+35+28+9=134)
일반적으로 120~180사이를 봅니다만, 불출 번호대에 따라서 많이 달라 지는것 같습니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 홀짝</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      당첨번호중 짝수와 홀수의 갯수를 나타냅니다. 홀짝패턴은 0:6 1:5 2:4 3:3 4:2 5:1 6:0의 7가지 패턴이 있습니다.
1:5 3:3 5:1이 나오면 총합은 홀수로, 0:6 2:4 4:2 6:0이 나오면 짝수로 나타 나겠죠.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 고저차</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      45개 번호중 23을 기준으로 23이하 숫자는 低(저) 23이상은 高(고)로 나눕니다. (ex: 낮은수 1~22 높은수 23~45)
저고패턴은 0:6 1:5 2:4 3:3 4:2 5:1 6:0의 7가지 패턴이 있습니다. 저고 패턴이 1:5이나 0:6이라면 총합은 올라 가겠고
반대면 내려 가겠죠.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>



              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 끝수</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      당첨번호를 십자리와 단자리로 나눈후 단자리에 해당하는 숫자들 입니다. (ex: 42 4/2 끝자리는 2)

당첨번호를 보면 끝자리가 같은수가 하나정도 포함됩니다.(31회의 경우 18번과 28번이 같은 끝수입니다.)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 끝수</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      당첨번호를 십자리와 단자리로 나눈후 단자리에 해당하는 숫자들 입니다. (ex: 42 4/2 끝자리는 2)

당첨번호를 보면 끝자리가 같은수가 하나정도 포함됩니다.(31회의 경우 18번과 28번이 같은 끝수입니다.)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 끝수합</strong></v-list-item-title>
                    <v-list-item-subtitle>
                     당첨번호를 십자리와 단자리로 나눈후 단자리를 모두 더한 값입니다. 보통 20~36사이 조금 좁게는 22~28을 봅니다.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 낙수</strong></v-list-item-title>
                    <v-list-item-subtitle>
당첨 번호 각각이 몇회전에 당첨된후 다시 당첨 번호로 나왔는지를 보는 것입니다.
(ex: 31회차 당첨번호중 18번은 28회에 나오고 31회에 나왓으므로 낙수는 3이 되것습니다. 이렇게 6개 번호를 하나하나
거꾸로 추적하시면 됩니다.)                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 낙첨합 (낙수합)</strong></v-list-item-title>
                    <v-list-item-subtitle>낙수 낙첨과 동일 개념으로 보시고 위에서 추적한 낙수의 합을 말합니다.             </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>



              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 산술적 복잡도 (AC값)</strong></v-list-item-title>
                    <v-list-item-subtitle>
                          AC값은 산술적 복잡성을 말하는 것으로 무작위로 6개 숫자를 뽑을 경우 숫자 간의 차이가 동일한 가능성이 낮습니다.

                          4~10까지의 숫자로 나타나는데, 국내외 로또 당첨번호를 보면 통상 7이상의 경우가 대다수이고 8,9,10이 주축을 이룹니다.



                          예를들어 1회 당첨번호가 10, 23, 29, 33, 37, 40 인 경우

                          40-37 / 40-33 / 40-29 / 40-23 / 40-10
                          37-33 / 37-29 / 37-23 / 37-10
                          33-29 / 33-23 / 33-10
                          29-23 / 29-10
                          23-10

                          계산 후 중복된 값 제외 -> 3,4,7,8,10,11,13,14,16,17,19,23,27,30 총14개
                          범위를 맞추기위해-5를 하게됩니다. 따라서 14-5 = 9
                          1회차 AC값은 9입니다.
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>


              <template>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-btn class="ma-2" large fab color="grey lighten-3">
                      <v-icon>mdi-trophy-outline</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong> 핫수, 콜드수 (뜨거운수 & 차가운수)</strong></v-list-item-title>
                    <v-list-item-subtitle>
                          사람에 따라 10회 기준으로 핫수와 콜드수 두가지로 분류 하기도하며, 보시는 분마다 적용하는 기준이 제각각인거 같습니다.
                          로또플레이는 핫수와 콜드수를 5주기준, 10주기준, 15주기준, 20주기준으로 다양한방법으로 보실수있도록 하였습니다.
                          <strong>핫수</strong><br>
                          최근 5주이내 출현한수를 핫수(뜨거운수)라고 합니다.<br>
                          <strong>콜드수</strong><br>
                          최근 5주이내 미출현한수를 콜드수(차가운수)라고 합니다.
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>













            </v-list>
          </v-card>




        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Store",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "판매점 분석" },
      { text: "로또 용어정리" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
